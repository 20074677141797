<template>
  <update-customer-address-info-modal :address="selectedAddress" edit-address-mode>
    <template v-slot:activator="{ on }">
      <div v-for="address in addresses" :key="address.id">
        <div class="d-flex justify-space-between">
          <span
            class="light-blue--text text--darken-4 font-weight-medium cursor-pointer"
            @click="openEditAddressModal(address, on)"
          >
            {{ getAddressName(address) }}
          </span>
          <v-icon class="light-blue--text text--darken-4 cursor-pointer">
            mdi-trash-can-outline
          </v-icon>
        </div>
        <v-divider class="my-1" />
      </div>
    </template>
  </update-customer-address-info-modal>
</template>

<script>
import UpdateCustomerAddressInfoModal from '@/components/customer/addresses/UpdateCustomerAddressModal.vue';

import CountriesService from '@/services/CountriesService';

export default {
  name: 'CustomerAddressesList',
  components: { UpdateCustomerAddressInfoModal },
  props: {
    addresses: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedAddress: {}
    };
  },
  methods: {
    getAddressName({ id, country, ...address }) {
      const countryName = CountriesService.getCountryLabel(country);
      const fullAddress = Object.values(address).join(' ');

      return `${countryName} ${fullAddress}`;
    },
    openEditAddressModal(address, { click }) {
      this.selectedAddress = { ...address };
      click();
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('data-table',{attrs:{"headers":_vm.$options.ordersHeadersConfig,"items":_vm.orderProducts,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getProductModifierClasses(item)},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getProductModifierClasses(item)},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.usage",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getProductModifierClasses(item)},[_vm._v(" "+_vm._s(item.usage)+" ")])]}},{key:"item.multiplier",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getProductModifierClasses(item)},[_vm._v("x"+_vm._s(item.multiplier))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getProductModifierClasses(item)},[_vm._v(" "+_vm._s(item.price)+" ")])]}}])}),_c('hr'),_c('div',{staticClass:"d-flex flex-column grey--text text--darken-3 mt-2 footer-total"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"footer-total__label"},[_vm._v("Subtotal")]),_c('div',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.treatment.currencyCode)+" "+_vm._s(_vm.treatment.amount))])]),(_vm.treatment.deliveryPrice)?_c('div',{staticClass:"d-flex mt-3"},[_c('div',{staticClass:"footer-total__label"},[_vm._v("Shipping")]),_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.treatment.currencyCode)+" "+_vm._s(_vm.treatment.deliveryPrice)+" ")])]):_vm._e(),(_vm.treatment.appliedPromoCodeAmount)?_c('div',{staticClass:"d-flex mt-3"},[_c('div',{staticClass:"footer-total__label"},[_vm._v("Promo code")]),_c('div',{staticClass:"font-weight-medium"},[_vm._v(" -"+_vm._s(_vm.treatment.currencyCode)+" "+_vm._s(_vm.treatment.appliedPromoCodeAmount)+" ")])]):_vm._e(),_c('div',{staticClass:"d-flex mt-3"},[_c('div',{staticClass:"footer-total__label"},[_vm._v("Order total")]),_c('div',{staticClass:"font-weight-medium blue--text"},[_vm._v(" "+_vm._s(_vm.treatment.currencyCode)+" "+_vm._s(_vm.orderTotal)+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
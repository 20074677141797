<template>
  <data-table
    :headers="$options.treatmentsHeadersConfig"
    :items="treatments"
    :loading="isLoading"
    :disable-pagination="true"
    :hide-default-footer="true"
  >
    <template v-slot:item.id="{ item }">
      <link-with-icon :link-text="`T${item.id}`" @click.native="onTreatmentClick(item)" />
    </template>
    <template v-slot:item.orderId="{ item }">
      <link-with-icon
        v-if="item.onlineOffer && item.paid"
        src="/icons/order-icon.svg"
        :link-text="item.orderId"
        :link-to="{ name: 'OrderDetails', params: { id: item.orderId } }"
      />
    </template>
  </data-table>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';
import LinkWithIcon from '@/components/common/LinkWithIcon.vue';

import { formatDateFieldsInArray, compareDates } from '@/utils';
import { getTreatmentsByCustomerId } from '@/api/customers.api';
import { ORDERS_STATUSES_LABELS } from '@/constants/orders';
import { TREATMENT_STATUSES } from '@/constants/treatment';

const TREATMENTS_TABLE_HEADER_CONFIG = [
  { text: 'Treatment Id', value: 'id', width: '175px' },
  { text: 'Date Created', value: 'creationDate', width: '175px', sort: compareDates },
  { text: 'Order', value: 'orderId', width: '175px' },
  { text: 'Cost', value: 'treatmentCost' }
];

export default {
  name: 'Treatments',
  components: { LinkWithIcon, DataTable },
  treatmentsHeadersConfig: TREATMENTS_TABLE_HEADER_CONFIG,
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      treatments: []
    };
  },
  async mounted() {
    this.treatments = await this.getCustomerTreatments(this.customer.id);
  },
  methods: {
    onTreatmentClick(treatment) {
      this.$emit('select-treatment', treatment);
    },
    transformTreatments(data) {
      const dataWithTransformedDates = formatDateFieldsInArray(data, [
        'creationDate',
        'expiredDate'
      ]);

      return dataWithTransformedDates.map(item => ({
        ...item,
        treatmentCost: `${item.currencyCode} ${item.amount}`,
        orderStatus: ORDERS_STATUSES_LABELS[item.orderStatus],
        statusEvent: TREATMENT_STATUSES[item.statusEvent]
      }));
    },
    async getCustomerTreatments(id) {
      try {
        this.isLoading = true;

        const { data } = await getTreatmentsByCustomerId(id);

        return this.transformTreatments(data);
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tab {
  max-width: 500px;
}
</style>

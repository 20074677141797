<template>
  <update-info-modal
    v-model="isModalShown"
    width="800"
    scrollable
    :loading="isLoading"
    modal-title="Edit user info"
    action-button-label="Save"
    :action-button-color="actionButtonColor"
    @close-modal="onModalClose"
    @action-button-click="onActionButtonClick"
    @cancel="onModalClose"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <template v-slot:modal-body>
      <v-form ref="customerInfoForm" :key="isModalShown" class="pt-7 pr-4 pb-5 pl-1">
        <customer-additional-info-form v-model="customerAdditionalInfo" :doctors="doctorsList" />
      </v-form>
    </template>
  </update-info-modal>
</template>

<script>
import { pick } from 'ramda';

import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';
import CustomerAdditionalInfoForm from '@/components/customer/CustomerAdditionalInfo.vue';

import { updateCustomer } from '@/api/customers.api';
import { getUsersList } from '@/api/users.api';

import { DEFAULT_BUTTON_COLOR, ERROR_BUTTON_COLOR , SORTING_DIRECTIONS } from '@/constants/common';

import { USER_ROLE } from '@/constants/user-roles';



const CUSTOMER_ADDITIONAL_INFO_FIELD_NAMES = ['locale', 'doctorId', 'phone', 'phoneIsoCode'];

const CUSTOMER_ADDITIONAL_DEFAULT_FIELDS = {
  locale: null,
  doctorId: null,
  phone: '',
  phoneIsoCode: ''
};

const ADMIN_VISIBLE_ROLES = [USER_ROLE.DOCTOR];

const SEARCH_FIELDS = [
  { value: 'NAME', text: 'Name' }
];

const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_PAGE_SIZE = 1000;

const FIELD_NAMES = {
  NAME: 'name',
  USERNAME: 'username',
  ROLE: 'roleLabel',
  IS_DEMO: 'demoAccount',
  LEVEL: 'doctorLevel',
  MICRONEEDLING: 'microneedlingEnabled',
  MEDICAL_CONCENTRATION: 'medicalConcentrationBlocked',
  EMAIL: 'email',
  COUNTRY: 'country',
  ID: 'Id'
};

const FIELD_NAMES_FOR_PARAMS = {
  [FIELD_NAMES.NAME]: 'NAME',
  [FIELD_NAMES.USERNAME]: 'USERNAME',
  [FIELD_NAMES.ROLE]: 'ROLE',
  [FIELD_NAMES.IS_DEMO]: 'IS_DEMO',
  [FIELD_NAMES.LEVEL]: 'LEVEL',
  [FIELD_NAMES.MICRONEEDLING]: 'MICRONEEDLING',
  [FIELD_NAMES.EMAIL]: 'EMAIL',
  [FIELD_NAMES.COUNTRY]: 'COUNTRY',
  [FIELD_NAMES.ID]: 'ID'
};

const SORT_DEFAULT = SORTING_DIRECTIONS.ASC;



export default {
  name: 'UpdateCustomerInfoModal',
  components: { UpdateInfoModal, CustomerAdditionalInfoForm },
  props: {
    customerInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isModalShown: false,
      isLoading: false,
      actionButtonColor: DEFAULT_BUTTON_COLOR,
      customerAdditionalInfo: { ...CUSTOMER_ADDITIONAL_DEFAULT_FIELDS },
      doctorsList: [],
      searchOptions: {
        searchString: '',
        searchField: SEARCH_FIELDS[0]
      },
    };
  },
  watch: {
    customerInfo() {
      this.getInfoFromCustomer();
    }
  },
  async mounted() {
    this.doctorsList = await this.getDoctors();
  },
  methods: {
    async getDoctors() {
      const userListVisibleRoles = ADMIN_VISIBLE_ROLES;

      const requestParams = {
          lettersToSearch: this.searchOptions.searchString,
          searchIn: this.searchOptions.searchField.value,
          sortType: FIELD_NAMES_FOR_PARAMS[FIELD_NAMES.NAME],
          sortDirection:SORT_DEFAULT,
          page:DEFAULT_PAGE_NUMBER,
          size:DEFAULT_PAGE_SIZE,
          roles: userListVisibleRoles
        };
        const {
          data: { users }
        } = await getUsersList(requestParams);
      return users.map(({ id, name , username}) => ({ text: name.concat(`(${  username })`), value: id }));
    },
    getInfoFromCustomer() {
      this.customerAdditionalInfo = pick(CUSTOMER_ADDITIONAL_INFO_FIELD_NAMES, this.customerInfo);
    },
    onModalClose() {
      this.isModalShown = false;
    },
    async onActionButtonClick() {
      const { customerInfoForm } = this.$refs;

      if (!customerInfoForm.validate()) {
        return;
      }

      this.isLoading = true;
      this.actionButtonColor = DEFAULT_BUTTON_COLOR;

      try {
        const registrationDate = new Date(this.customerInfo.registrationDate).toISOString();

        const customerInfo = {
          ...this.customerInfo,
          ...this.customerAdditionalInfo,
          registrationDate
        };

        await updateCustomer(this.customerInfo.id, customerInfo);

        this.isModalShown = false;
        this.$emit('update-customer');
      } catch (error) {
        this.actionButtonColor = ERROR_BUTTON_COLOR;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

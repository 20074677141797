<template>
  <section class="additional-user-info">
    <div class="additional-user-info__personal-info personal-info pb-5">

      <v-text-field
      v-model="searchString"
      class="filter-box flex-grow-0"
      label="Doctors filter"
      prepend-icon="mdi-magnify"
      single-line
      clearable
      hide-details
     />

      <base-select
        v-for="select in customerSelectFields"
        :key="select.key"
        class="mb-5"
        :value="value[select.fieldName]"
        v-bind="select"
        @input="updateInputField($event, select)"
      />

 

      <phone-number-input
        v-model="phoneNumber"
        class="mb-5"
        :default-country="value.phoneIsoCode"
        placeholder="Mobile phone number"
        @blur="updatePrivatePhoneField"
        @country-changed="updatePrivatePhoneIsoField"
      />
    </div>
  </section>
</template>

<script>
import SelectFieldConfiguration from '@/models/input-models/SelectFieldConfiguration';
import PhoneNumberInput from '@/components/common/PhoneNumberInput.vue';
import BaseSelect from '@/components/common/BaseSelect.vue';

import { VALIDATION_RULES, LANGUAGE_SELECT_ITEMS } from '@/constants/common';



export default {
  name: 'CustomerAdditionalInfo',
  validationRules: VALIDATION_RULES,
  components: { BaseSelect, PhoneNumberInput },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    doctors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      phoneNumber: '',
      searchString:''
    };
  },
  computed:
  {
    customerSelectFields() {return  [
      new SelectFieldConfiguration({
        fieldName: 'locale',
        label: 'Language *',
        rules: [VALIDATION_RULES.REQUIRED],
        items: LANGUAGE_SELECT_ITEMS
      }),
      new SelectFieldConfiguration({
        fieldName: 'doctorId',
        label: 'Doctor *',
        rules: [VALIDATION_RULES.REQUIRED],
        items: this.filteredDoctors
      })
    ]},
     filteredDoctors() { 
      return this.doctors.filter((el) => el.text !== null &&  el.text.toLowerCase().includes(this.searchString.toLowerCase()));
    }
 },
  mounted() {
    this.phoneNumber = this.value.phone;
  },
  methods: {
    updateInputField(newValue, { fieldName }) {
      this.$emit('input', { ...this.value, [fieldName]: newValue });
    },
    updatePrivatePhoneField() {
      this.updateInputField(this.phoneNumber, { fieldName: 'phone' });
    },
    updatePrivatePhoneIsoField({ iso2 }) {
      this.updateInputField(iso2, { fieldName: 'phoneIsoCode' });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/user/update-user-modal';

.personal-info {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
}
</style>

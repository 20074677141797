<template>
  <base-modal
    :value="isOpen"
    modal-title="Delete customer?"
    has-close-button
    max-width="420"
    @cancel="onCancel"
  >
    <template v-slot:modal-actions>
      <div class="flex">
        <v-btn
          tile
          large
          color="light-blue darken-4 "
          class="white--text text-capitalize"
          @click="onDelete"
        >
          Delete
        </v-btn>
        <v-btn
          class="font-weight-bold text-capitalize"
          color="light-blue darken-4"
          text
          @click="onCancel"
        >
          Cancel
        </v-btn>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/common/BaseModal.vue';

export default {
  name: 'CustomerDeletingConfirmModal',
  components: { BaseModal },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    onDelete() {
      this.$emit('delete-customer');
    },
    onCancel() {
      this.$emit('close-modal');
    }
  }
};
</script>

<template>
  <section>
    <details-list
      :details="transformedCustomer"
      :columns="$options.detailsConfig"
      class="details-list"
    >
      <template v-slot:additional.doctorName.content>
        <div class="d-flex">
          <link-with-icon
            :link-text="transformedCustomer.doctorName"
            :link-to="{ name: 'UserDetails', params: { id: transformedCustomer.doctorId } }"
          >
            <template v-slot:icon>
              <v-icon class="mr-2">mdi-account-outline</v-icon>
            </template>
          </link-with-icon>
        </div>
      </template>
    </details-list>

    <update-customer-info-modal :customer-info="customerInfo" @update-customer="updateCustomer">
      <template v-slot:activator="{ on }">
        <v-btn
          tile
          large
          outlined
          color="light-blue darken-4"
          class="white--text"
          @click="onOpenModalButtonClick(on)"
        >
          <v-icon left>mdi-pencil</v-icon> Edit
        </v-btn>
      </template>
    </update-customer-info-modal>
  </section>
</template>

<script>
import { pathOr } from 'ramda';

import DetailsList from '@/components/common/DetailsList.vue';
import LinkWithIcon from '@/components/common/LinkWithIcon.vue';
import UpdateCustomerInfoModal from '@/components/customer/UpdateCustomerInfoModal.vue';

import CountriesService from '@/services/CountriesService';
import { getFormattedPhone } from '@/utils';

import { LOCALE } from '@/constants/locales';

const DETAILS_FIELDS_CONFIG = [
  {
    columnName: 'general',
    columnWidth: '250px',
    fields: [
      {
        fieldLabel: 'Name',
        fieldName: 'customerName'
      },
      {
        fieldLabel: 'Email',
        fieldName: 'email'
      },
      {
        fieldLabel: 'Registration date',
        fieldName: 'registrationDate'
      }
    ]
  },
  {
    columnName: 'additional',
    columnWidth: '250px',
    fields: [
      {
        fieldLabel: 'Gender',
        fieldName: 'gender'
      },
      {
        fieldLabel: 'Language',
        fieldName: 'customerLanguage'
      },
      {
        fieldLabel: 'Country',
        fieldName: 'customerCountry'
      },
      {
        fieldLabel: 'Clinic association',
        fieldName: 'clinicName'
      },
      {
        fieldLabel: 'Linked to a doctor',
        fieldName: 'doctorName'
      },
      {
        fieldLabel: 'Phone number',
        fieldName: 'customerPhone'
      }
    ]
  }
];

export default {
  name: 'CustomerDetailsTab',
  components: { UpdateCustomerInfoModal, DetailsList, LinkWithIcon },
  detailsConfig: DETAILS_FIELDS_CONFIG,
  props: {
    customer: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      customerInfo: null
    };
  },
  computed: {
    transformedCustomer() {
      return this.transformCustomer(this.customer);
    }
  },
  methods: {
    onOpenModalButtonClick({ click }) {
      this.customerInfo = { ...this.customer };
      click();
    },
    updateCustomer() {
      this.$emit('update-customer');
    },
    transformCustomer(customer) {
      const {
        firstName,
        lastName,
        locale,
        countryOfResidence,
        phone,
        phoneIsoCode,
        clinicDto
      } = customer;

      const clinicName = pathOr(null, ['clinicName'], clinicDto);
      const customerPhone = getFormattedPhone({ isoCode: phoneIsoCode, phoneNumber: phone });
      const countryLabel = CountriesService.getCountryLabel(countryOfResidence);
      const customerCountry = countryOfResidence && `${countryLabel} (${countryOfResidence})`;

      return {
        ...customer,
        customerName: `${firstName} ${lastName}`,
        customerLanguage: LOCALE[locale],
        customerCountry,
        customerPhone,
        clinicName
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.tab {
  max-width: 500px;
}

.details-list {
  width: 500px;
}
</style>

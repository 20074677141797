<template>
  <div>
    <div class="mb-5 ml-1">
      <v-icon class="mr-2" size="14" @click="onClose">mdi-close</v-icon>
      <span class="grey--text text--darken-3">Treatment T{{ treatment.id }}</span>
    </div>
    <details-list :details="treatment" :columns="$options.detailsConfig" class="details-list">
      <template v-if="treatment.orderId" v-slot:order.orderStatus.content>
        <link-with-icon
          icon-url="/icons/order-icon.svg"
          :link-text="treatment.orderId"
          :link-to="{ name: 'OrderDetails', params: { id: treatment.orderId } }"
        />
      </template>
    </details-list>
    <hr />
    <div class="mt-5">
      <div class="grey--text">Treatment composition</div>
      <div v-if="treatment.orderId" class="d-inline-flex align-center ml-5 text--darken-4">
        <span>Treatment offer</span>
        <v-switch v-model="showOrder" class="mx-5" :disabled="!hasOnlineOrdersDetails"></v-switch>
        <span :class="{ 'order-active': showOrder }">Order</span>
      </div>
      <treatment-composition v-if="!showOrder" :treatment="treatment" />
      <order-composition v-else :treatment="treatment" />
    </div>
  </div>
</template>

<script>
import DetailsList from '@/components/common/DetailsList.vue';
import TreatmentComposition from '@/components/customer/TreatmentComposition.vue';
import OrderComposition from '@/components/customer/OrderComposition.vue';
import LinkWithIcon from '@/components/common/LinkWithIcon.vue';

const DETAILS_FIELDS_CONFIG = [
  {
    columnName: 'general',
    columnWidth: '250px',
    fields: [
      {
        fieldLabel: 'Based on',
        fieldName: 'diagnosticId'
      }
    ]
  },
  {
    columnName: 'dateAndStatus',
    columnWidth: '250px',
    fields: [
      {
        fieldLabel: 'Date created',
        fieldName: 'creationDate'
      },
      {
        fieldLabel: 'Completion Date',
        fieldName: 'expiredDate'
      },
      {
        fieldLabel: 'Status',
        fieldName: 'statusEvent'
      }
    ]
  },
  {
    columnName: 'order',
    columnWidth: '250px',
    fields: [
      {
        fieldLabel: 'Order',
        fieldName: 'orderStatus'
      }
    ]
  }
];

export default {
  name: 'TreatmentDetails',
  components: { DetailsList, TreatmentComposition, LinkWithIcon, OrderComposition },
  detailsConfig: DETAILS_FIELDS_CONFIG,
  props: {
    treatment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showOrder: false
    };
  },
  computed: {
    hasOnlineOrdersDetails() {
      const { onlineOffer, paid } = this.treatment;
      return onlineOffer && paid;
    }
  },
  methods: {
    onClose() {
      this.$emit('close-details');
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-total {
  position: relative;
  left: 616px;

  &__label {
    width: 150px;
  }
}

.order-active {
  color: #82b1ff;
}

.details-list {
  width: 750px;
}
</style>

<template>
  <div class="d-flex flex-column addresses-list">
    <update-customer-address-info-modal
      :address="selectedAddress"
      @update-address="updateCustomerAddress"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          tile
          large
          color="light-blue darken-4"
          class="white--text my-10"
          width="166"
          @click="openAddAddressModal(on)"
        >
          Add address
        </v-btn>
      </template>
    </update-customer-address-info-modal>

    <customer-addresses-list :addresses="$options.addresses" />
  </div>
</template>

<script>
import CustomerAddressesList from '@/components/customer/addresses/CustomerAddressesList.vue';
import UpdateCustomerAddressInfoModal from '@/components/customer/addresses/UpdateCustomerAddressModal.vue';

import { DEFAULT_ADDRESS_FIELDS } from '@/constants/common';

// TODO: remove const after back-end will be implemented
const ADDRESSES = [
  {
    id: 1,
    country: 'US',
    city: 'Boston',
    zip: '27015',
    street: 'Magic str.',
    building: '24',
    apartment: '11'
  },
  {
    id: 2,
    country: 'AU',
    city: 'Sidney',
    zip: '65222',
    street: 'Awesome str.',
    building: '13',
    apartment: '777'
  }
];

export default {
  name: 'CustomerAddressesTab',
  components: { UpdateCustomerAddressInfoModal, CustomerAddressesList },
  addresses: ADDRESSES,
  props: {
    customer: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedAddress: {}
    };
  },
  methods: {
    openAddAddressModal({ click }) {
      this.selectedAddress = { ...DEFAULT_ADDRESS_FIELDS };
      click();
    },
    updateCustomerAddress() {
      this.$emit('update-customer-address');
    }
  }
};
</script>

<style lang="scss" scoped>
.addresses-list {
  width: 750px;
}
</style>

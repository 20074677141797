<template>
  <data-table
    :headers="$options.diagnosticsHeadersConfig"
    :items="diagnostics"
    :loading="isLoading"
    :disable-pagination="true"
    :hide-default-footer="true"
  />
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';

import { formatDateFieldsInArray, compareDates } from '@/utils';
import { getDiagnosticsByCustomerId } from '@/api/customers.api';
import { DIAGNOSTIC_TYPES } from '@/constants/diagnostics';

const DIAGNOSTICS_TABLE_HEADER_CONFIG = [
  { text: 'Diagnostic Id', value: 'diagnosticId', width: '175px' },
  { text: 'Date Created', value: 'date', width: '175px', sort: compareDates },
  { text: 'Source', value: 'type' }
];

export default {
  name: 'CustomerDiagnosticsTab',
  components: { DataTable },
  diagnosticsHeadersConfig: DIAGNOSTICS_TABLE_HEADER_CONFIG,
  props: {
    customer: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isLoading: false,
      diagnostics: []
    };
  },
  computed: {},
  async mounted() {
    this.diagnostics = await this.getCustomerDiagnostics(this.customer.id);
  },
  methods: {
    async getCustomerDiagnostics(id) {
      try {
        this.isLoading = true;

        const { data } = await getDiagnosticsByCustomerId(id);

        return this.transformDiagnostics(data);
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
      }
    },
    transformDiagnostics(data) {
      const dataWithFormattedDates = formatDateFieldsInArray(data, ['date']);

      return dataWithFormattedDates.map(item => ({
        ...item,
        diagnosticId: `D${item.id}`,
        type: DIAGNOSTIC_TYPES[item.type]
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
.tab {
  max-width: 500px;
}
</style>

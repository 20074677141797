<template>
  <section>
    <detail-page-template class="blue-grey--text text--darken-3">
      <template v-slot:header>
        <div class="d-flex align-center justify-space-between flex-grow-1">
          <div class="d-flex align-center">
            <v-avatar color="light-blue lighten-5" size="160">
              <img v-if="customer.photo" :src="customer.photo" alt="Customer photo" />
              <v-icon v-else size="55">mdi-account-outline</v-icon>
            </v-avatar>

            <div class="header-customer-name ml-5">
              <span class="display-1 align-self-center"
                >{{ customer.firstName }} {{ customer.lastName }}</span
              >
              <span class="header-customer-name__id body-2 blue-grey--text text--lighten-2">
                Id {{ customer.id }}
              </span>
            </div>
          </div>
          <dots-dropdown
            :items="$options.customerDotsMenuConfig"
            @select-item="onDropdownItemSelect"
          />
        </div>
      </template>
      <template v-slot:content>
        <v-tabs color="blue-grey darken-3" height="30">
          <v-tab class="text-none">Details</v-tab>
          <v-tab class="text-none">Diagnostics</v-tab>
          <v-tab class="text-none">Treatments</v-tab>
          <v-tab class="text-none">Addresses</v-tab>

          <v-tab-item class="mt-8">
            <v-card flat tile>
              <v-card-text class="px-0">
                <customer-details-tab :customer="customer" @update-customer="updateCustomer" />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat tile>
              <v-card-text class="px-0">
                <customer-diagnostics-tab :customer="customer" />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat tile>
              <v-card-text class="px-0">
                <customer-treatments-tab :customer="customer" />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat tile>
              <v-card-text class="px-0">
                <customer-addresses-tab
                  :customer="customer"
                  @update-customer-address="updateCustomer"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </template>
    </detail-page-template>

    <customer-deleting-confirm-modal
      :is-open="isDeleteModalShown"
      @delete-customer="onDeleteCustomer"
      @close-modal="onCloseModal"
    />
  </section>
</template>

<script>
import DetailPageTemplate from '@/components/tempates/DetailPageTemplate.vue';
import CustomerDetailsTab from '@/components/customer/CustomerDetailsTab.vue';
import CustomerDiagnosticsTab from '@/components/customer/CustomerDiagnosticsTab.vue';
import CustomerTreatmentsTab from '@/components/customer/CustomerTreatmentsTab.vue';
import CustomerAddressesTab from '@/components/customer/CustomerAddressesTab.vue';
import DotsDropdown from '@/components/users-page/DotsDropdown.vue';
import CustomerDeletingConfirmModal from '@/components/customer/CustomerDeletingConfirmModal.vue';

import { buildImageURL } from '@/utils';
import { getCustomerById, deleteCustomer } from '@/api/customers.api';
import { DEFAULT_DATE_FORMAT } from '@/constants/common';
import { USERS_PAGE } from '@/constants/users-page';
import DateFormatService from '@/services/DateFormatService';

const CUSTOMER_DOTS_MENU_ITEM = {
  DELETE_CUSTOMER: { id: 1, title: 'Delete customer' }
};

export default {
  name: 'CustomerDetailPage',
  components: {
    CustomerAddressesTab,
    CustomerTreatmentsTab,
    CustomerDiagnosticsTab,
    DetailPageTemplate,
    CustomerDetailsTab,
    DotsDropdown,
    CustomerDeletingConfirmModal
  },
  customerDotsMenuConfig: [CUSTOMER_DOTS_MENU_ITEM.DELETE_CUSTOMER],
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      customer: {
        name: '',
        photo: null
      },
      isDeleteModalShown: false
    };
  },
  mounted() {
    this.getCustomer(this.id);
  },
  methods: {
    transformCustomerData(customer) {
      const { photo, registrationDate: date, countryOfResidence } = customer;
      const country = countryOfResidence ? countryOfResidence.toUpperCase() : null;
      const registrationDate = DateFormatService.formatDate(new Date(date), DEFAULT_DATE_FORMAT);

      return {
        ...customer,
        countryOfResidence: country,
        registrationDate,
        photo: buildImageURL({ photo })
      };
    },
    async getCustomer(id) {
      const { data } = await getCustomerById(id);

      this.customer = this.transformCustomerData(data);
    },
    updateCustomer() {
      this.getCustomer(this.id);
    },
    async onDeleteCustomer() {
      this.isDeleteModalShown = false;
      await deleteCustomer(this.id);

      this.$router.push({ name: USERS_PAGE.CUSTOMERS });
    },
    onCloseModal() {
      this.isDeleteModalShown = false;
    },
    onDropdownItemSelect() {
      this.isDeleteModalShown = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-customer-name {
  position: relative;

  &__id {
    min-width: 80px;
    position: absolute;

    bottom: -25px;
    left: 0;
  }
}
</style>

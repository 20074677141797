<template>
  <div class="mt-5">
    <data-table
      :headers="$options.ordersHeadersConfig"
      :items="orderProducts"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.name="{ item }">
        <div :class="getProductModifierClasses(item)">
          {{ item.name }}
        </div>
      </template>

      <template v-slot:item.description="{ item }">
        <div :class="getProductModifierClasses(item)">
          {{ item.description }}
        </div>
      </template>

      <template v-slot:item.usage="{ item }">
        <div :class="getProductModifierClasses(item)">
          {{ item.usage }}
        </div>
      </template>

      <template v-slot:item.multiplier="{ item }">
        <div :class="getProductModifierClasses(item)">x{{ item.multiplier }}</div>
      </template>

      <template v-slot:item.price="{ item }">
        <div :class="getProductModifierClasses(item)">
          {{ item.price }}
        </div>
      </template>
    </data-table>
    <hr />
    <div class="d-flex flex-column grey--text text--darken-3 mt-2 footer-total">
      <div class="d-flex">
        <div class="footer-total__label">Subtotal</div>
        <div class="font-weight-medium">{{ treatment.currencyCode }} {{ treatment.amount }}</div>
      </div>
      <div v-if="treatment.deliveryPrice" class="d-flex mt-3">
        <div class="footer-total__label">Shipping</div>
        <div class="font-weight-medium">
          {{ treatment.currencyCode }} {{ treatment.deliveryPrice }}
        </div>
      </div>
      <div v-if="treatment.appliedPromoCodeAmount" class="d-flex mt-3">
        <div class="footer-total__label">Promo code</div>
        <div class="font-weight-medium">
          -{{ treatment.currencyCode }}
          {{ treatment.appliedPromoCodeAmount }}
        </div>
      </div>
      <div class="d-flex mt-3">
        <div class="footer-total__label">Order total</div>
        <div class="font-weight-medium blue--text">
          {{ treatment.currencyCode }} {{ orderTotal }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { chain, isEmpty } from 'ramda';

import DataTable from '@/components/common/DataTable.vue';

import CompositionProduct from '@/models/CompositionProduct';

import { getBottleDescription } from '@/utils/treatment';

import {
  TREATMENT_COMPOSITION_SECTION_NAMES,
  SERUM_USAGE_KEYS,
  SERUM_USAGE_ABBREVIATION,
  TREATMENT_PRODUCT_STATUS
} from '@/constants/treatment';

const TREATMENT_ORDER_TABLE_HEADER_CONFIG = [
  { text: 'Product', value: 'name', width: '250px' },
  { text: 'Actives', value: 'description', width: '350px' },
  { text: 'Use', value: 'usage', width: '150px' },
  { text: '', value: 'multiplier', width: '50px' },
  { text: 'Cost', value: 'price' }
];

export default {
  name: 'OrderComposition',
  components: { DataTable },
  ordersHeadersConfig: TREATMENT_ORDER_TABLE_HEADER_CONFIG,
  props: {
    treatment: {
      type: Object,
      required: true
    }
  },
  computed: {
    orderProducts() {
      return this.getProducts(this.treatment);
    },
    orderTotal() {
      const { appliedPromoCodeAmount, deliveryPrice, orderPaidAmount, amount } = this.treatment;
      const shouldShowOrderPaidAmount = appliedPromoCodeAmount || deliveryPrice;

      return shouldShowOrderPaidAmount ? orderPaidAmount : amount;
    }
  },
  methods: {
    getProducts({ treatmentDetails }) {
      if (!treatmentDetails) {
        return [];
      }

      const { treatFace, treatEyes, cleanse, moisturize, protect } = treatmentDetails;

      const treatSections = [
        { section: treatFace, name: TREATMENT_COMPOSITION_SECTION_NAMES.TREAT_FACE },
        { section: treatEyes, name: TREATMENT_COMPOSITION_SECTION_NAMES.TREAT_EYES }
      ];

      const complementaryCareSections = [
        { section: cleanse, name: TREATMENT_COMPOSITION_SECTION_NAMES.CLEANSE },
        { section: moisturize, name: TREATMENT_COMPOSITION_SECTION_NAMES.MOISTURIZER },
        { section: protect, name: TREATMENT_COMPOSITION_SECTION_NAMES.PROTECT }
      ];

      const treatSectionsComposition = chain(
        ({ section, name }) => this.getTreatComposition(section, name),
        treatSections
      );

      const complementaryCareComposition = chain(
        ({ section, name }) => this.getComplementaryCareProductsComposition(section, name),
        complementaryCareSections
      );

      return [...treatSectionsComposition, ...complementaryCareComposition];
    },
    getTreatComposition(treat, compositionName) {
      const { cost, formulasOptions, status } = treat;

      if (!formulasOptions) {
        return null;
      }

      const { twoFormulas, oneFormula } = formulasOptions;

      if (twoFormulas.isFound) {
        return [...this.getFormulaComposition(twoFormulas, cost, compositionName, status)];
      }

      if (oneFormula.isFound) {
        return [this.getFormulaComposition(oneFormula, cost, compositionName, status)];
      }

      return [];
    },
    getFormulaComposition(formula, cost, compositionName, status) {
      const { usage, compositionBottle1, compositionBottle2, nbOfBottles } = formula;
      const price = parseFloat(cost);
      const isDeleted = status === TREATMENT_PRODUCT_STATUS.REMOVED_BY_PATIENT;
      const multiplier = isDeleted ? 0 : nbOfBottles;
      const isUpdated =
        status === TREATMENT_PRODUCT_STATUS.MODIFIED_BY_PATIENT ||
        status === TREATMENT_PRODUCT_STATUS.ADDED_BY_PATIENT;

      if (usage === SERUM_USAGE_KEYS.ONE_MORNING_ONE_EVENING) {
        const firstDescription = getBottleDescription(compositionBottle1);
        const secondDescription = getBottleDescription(compositionBottle2);
        const { price: firstBottlePrice } = compositionBottle1;
        const { price: secondBottlePrice } = compositionBottle2;

        return [
          new CompositionProduct({
            name: compositionName,
            description: firstDescription,
            usage: SERUM_USAGE_ABBREVIATION[SERUM_USAGE_KEYS.MORNING_ONLY],
            price: `${this.treatment.currencyCode} ${firstBottlePrice}`,
            multiplier,
            isUpdated,
            isDeleted
          }),
          new CompositionProduct({
            name: compositionName,
            description: secondDescription,
            usage: SERUM_USAGE_ABBREVIATION[SERUM_USAGE_KEYS.EVENING_ONLY],
            price: `${this.treatment.currencyCode} ${secondBottlePrice}`,
            multiplier,
            isUpdated,
            isDeleted
          })
        ];
      }

      const formulaBottle = compositionBottle1 || compositionBottle2;
      const formulaBottleDescription = getBottleDescription(formulaBottle);

      return new CompositionProduct({
        name: compositionName,
        description: formulaBottleDescription,
        usage: SERUM_USAGE_ABBREVIATION[usage],
        price: `${this.treatment.currencyCode} ${price}`,
        multiplier,
        isUpdated,
        isDeleted
      });
    },
    getComplementaryCareProductsComposition(complementaryCare, sectionName) {
      const { products } = complementaryCare;

      if (isEmpty(products) || !Array.isArray(products)) {
        return [];
      }

      return products.map(product => {
        const { orderMultiplier, status } = product;
        const isDeleted = status === TREATMENT_PRODUCT_STATUS.REMOVED_BY_PATIENT;
        const isUpdated =
          status === TREATMENT_PRODUCT_STATUS.MODIFIED_BY_PATIENT ||
          status === TREATMENT_PRODUCT_STATUS.ADDED_BY_PATIENT;

        return new CompositionProduct({
          name: product.name,
          description: product && sectionName,
          usage: SERUM_USAGE_ABBREVIATION[product.usage],
          price: `${this.treatment.currencyCode} ${product.price}`,
          multiplier: orderMultiplier,
          isUpdated,
          isDeleted
        });
      });
    },
    getProductModifierClasses({ isUpdated, isDeleted }) {
      return {
        'blue--text text--darken-3': isUpdated,
        'deleted-product-text': isDeleted
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-total {
  position: relative;
  left: 616px;

  &__label {
    width: 150px;
  }
}

.deleted-product-text {
  text-decoration-line: line-through;
  opacity: 0.3;
}
</style>

<template>
  <div>
    <template v-if="!isDetailedView">
      <treatments :customer="customer" @select-treatment="handleTreatmentSelection(true, $event)" />
    </template>
    <template v-else>
      <treatment-details
        :treatment="selectedTreatment"
        @close-details="handleTreatmentSelection(false)"
      />
    </template>
  </div>
</template>

<script>
import Treatments from '@/components/customer/Treatments.vue';
import TreatmentDetails from '@/components/customer/TreatmentDetails.vue';

export default {
  name: 'CustomerTreatmentsTab',
  components: { TreatmentDetails, Treatments },
  props: {
    customer: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isDetailedView: false,
      selectedTreatment: null
    };
  },
  methods: {
    handleTreatmentSelection(showDetailedView, treatment = null) {
      this.isDetailedView = showDetailedView;
      this.selectedTreatment = treatment;
    }
  }
};
</script>

<template>
  <div class="new-clinic-section">
    <base-select
      class="mb-5"
      :value="value.country"
      v-bind="$options.selectCountryConfig"
      @input="updateAddressField($event, 'country')"
    />

    <base-text-field
      v-for="textField in $options.addressTextFields"
      :key="textField.key"
      class="mb-5"
      :value="value[textField.fieldName]"
      v-bind="textField"
      @change="updateAddressField($event, textField.fieldName)"
    />
  </div>
</template>

<script>
import BaseTextField from '@/components/common/BaseTextField.vue';
import BaseSelect from '@/components/common/BaseSelect.vue';

import SelectFieldConfiguration from '@/models/input-models/SelectFieldConfiguration';
import TextFieldConfiguration from '@/models/input-models/TextFieldConfiguration';

import { COUNTRY_SELECT_ITEMS } from '@/constants/common';

const SELECT_COUNTRY_CONFIG = new SelectFieldConfiguration({
  fieldName: 'country',
  label: 'Country',
  items: COUNTRY_SELECT_ITEMS
});

const ADDRESS_LABELS_ARRAY = ['City', 'Zip', 'Street', 'Building', 'Apartment'];

const ADDRESS_TEXT_FIELDS_CONFIG = ADDRESS_LABELS_ARRAY.map(label => {
  return new TextFieldConfiguration({
    fieldName: label.toLowerCase(),
    label: `${label}`
  });
});

export default {
  name: 'NewAddressSection',
  components: { BaseTextField, BaseSelect },
  selectCountryConfig: SELECT_COUNTRY_CONFIG,
  addressTextFields: ADDRESS_TEXT_FIELDS_CONFIG,
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    updateAddressField(newValue, fieldName) {
      this.$emit('input', { ...this.value, [fieldName]: newValue });
    }
  }
};
</script>

<template>
  <div class="mt-5">
    <data-table
      :headers="$options.treatmentsHeadersConfig"
      :items="treatmentOffer"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.multiplier="{ item }">
        <div>x{{ item.multiplier }}</div>
      </template>
      <template v-slot:item.price="{ item: { price } }">
        {{ treatment.currencyCode }} {{ price }}
      </template>
    </data-table>
    <hr />
    <div class="d-flex grey--text text--darken-3 mt-2 footer-total">
      <div class="footer-total__label">Total</div>
      <div class="font-weight-medium">{{ treatment.currencyCode }} {{ treatmentAmount }}</div>
    </div>
  </div>
</template>

<script>
import { chain, isEmpty } from 'ramda';

import DataTable from '@/components/common/DataTable.vue';

import CompositionProduct from '@/models/CompositionProduct';

import {
  TREATMENT_COMPOSITION_SECTION_NAMES,
  SERUM_USAGE_KEYS,
  SERUM_USAGE_ABBREVIATION,
  TREATMENT_PRODUCT_STATUS
} from '@/constants/treatment';
import { getBottleDescription } from '@/utils/treatment';

const TREATMENT_OFFER_TABLE_HEADER_CONFIG = [
  { text: 'Product', value: 'name', width: '250px' },
  { text: 'Actives', value: 'description', width: '350px' },
  { text: 'Use', value: 'usage', width: '150px' },
  { text: '', value: 'multiplier', width: '50px' },
  { text: 'Cost', value: 'price' }
];

export default {
  name: 'TreatmentComposition',
  components: { DataTable },
  treatmentsHeadersConfig: TREATMENT_OFFER_TABLE_HEADER_CONFIG,
  props: {
    treatment: {
      type: Object,
      required: true
    }
  },
  computed: {
    treatmentOffer() {
      return this.getProducts(this.treatment);
    },
    treatmentAmount() {
      return this.treatmentOffer.reduce((totalPrice, { price }) => totalPrice + price, 0);
    }
  },
  methods: {
    getProducts({ treatmentDetails }) {
      if (!treatmentDetails) {
        return [];
      }

      const { treatFace, treatEyes, cleanse, moisturize, protect } = treatmentDetails;

      const treatSections = [
        { section: treatFace, name: TREATMENT_COMPOSITION_SECTION_NAMES.TREAT_FACE },
        { section: treatEyes, name: TREATMENT_COMPOSITION_SECTION_NAMES.TREAT_EYES }
      ];

      const complementaryCareSections = [
        { section: cleanse, name: TREATMENT_COMPOSITION_SECTION_NAMES.CLEANSE },
        { section: moisturize, name: TREATMENT_COMPOSITION_SECTION_NAMES.MOISTURIZER },
        { section: protect, name: TREATMENT_COMPOSITION_SECTION_NAMES.PROTECT }
      ];

      const treatSectionsComposition = chain(
        ({ section, name }) => this.getTreatComposition(section, name),
        treatSections
      );

      const complementaryCareComposition = chain(
        ({ section, name }) => this.getComplementaryCareProductsComposition(section, name),
        complementaryCareSections
      );

      return [...treatSectionsComposition, ...complementaryCareComposition];
    },
    getTreatComposition(treat, compositionName) {
      const { cost, formulasOptions, status } = treat;

      if (!formulasOptions || status === TREATMENT_PRODUCT_STATUS.ADDED_BY_PATIENT) {
        return null;
      }

      const { twoFormulas, oneFormula } = formulasOptions;

      if (twoFormulas.isFound) {
        return [...this.getFormulaComposition(twoFormulas, cost, compositionName, status)];
      }

      if (oneFormula.isFound) {
        return [this.getFormulaComposition(oneFormula, cost, compositionName, status)];
      }

      return [];
    },
    getFormulaComposition(formula, cost, compositionName, status) {
      const { usage, compositionBottle1, compositionBottle2, nbOfBottles } = formula;
      const price = parseFloat(cost);
      const isDeleted = status === TREATMENT_PRODUCT_STATUS.REMOVED_BY_PATIENT;
      const multiplier = isDeleted ? 0 : nbOfBottles;

      if (usage === SERUM_USAGE_KEYS.ONE_MORNING_ONE_EVENING) {
        const firstDescription = getBottleDescription(compositionBottle1);
        const secondDescription = getBottleDescription(compositionBottle2);
        const { price: firstBottlePrice } = compositionBottle1;
        const { price: secondBottlePrice } = compositionBottle2;

        return [
          new CompositionProduct({
            name: compositionName,
            description: firstDescription,
            usage: SERUM_USAGE_ABBREVIATION[SERUM_USAGE_KEYS.MORNING_ONLY],
            price: firstBottlePrice,
            multiplier
          }),
          new CompositionProduct({
            name: compositionName,
            description: secondDescription,
            usage: SERUM_USAGE_ABBREVIATION[SERUM_USAGE_KEYS.EVENING_ONLY],
            price: secondBottlePrice,
            multiplier
          })
        ];
      }

      const formulaBottle = compositionBottle1 || compositionBottle2;

      const formulaBottleDescription = getBottleDescription(formulaBottle);

      return new CompositionProduct({
        name: compositionName,
        description: formulaBottleDescription,
        usage: SERUM_USAGE_ABBREVIATION[usage],
        price,
        multiplier
      });
    },
    getComplementaryCareProductsComposition(complementaryCare, sectionName) {
      const { products } = complementaryCare;

      if (isEmpty(products) || !Array.isArray(products)) {
        return [];
      }

      return products
        .filter(({ status }) => status !== TREATMENT_PRODUCT_STATUS.ADDED_BY_PATIENT)
        .map(({ name, usage, price }) => {
          return new CompositionProduct({
            name,
            description: sectionName,
            usage: SERUM_USAGE_ABBREVIATION[usage],
            price
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-total {
  position: relative;
  left: 616px;

  &__label {
    width: 150px;
  }
}
</style>

<template>
  <update-info-modal
    v-model="isAddressModalShown"
    width="420"
    scrollable
    action-button-label="Save"
    :loading="isLoading"
    :modal-title="addressModalTitle"
    :action-button-color="actionButtonColor"
    @close-modal="closeModal"
    @action-button-click="onActionButtonClick"
    @cancel="closeModal"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <template v-slot:modal-body>
      <v-form ref="customerAddressForm" class="pt-1">
        <new-address-section v-model="addressInfo" />
      </v-form>
    </template>
  </update-info-modal>
</template>

<script>
import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';
import NewAddressSection from '@/components/customer/addresses/NewAddressSection.vue';

import { DEFAULT_BUTTON_COLOR, DEFAULT_ADDRESS_FIELDS } from '@/constants/common';

export default {
  name: 'UpdateCustomerAddressModal',
  components: { NewAddressSection, UpdateInfoModal },
  props: {
    address: {
      type: Object,
      default: () => ({})
    },
    editAddressMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isAddressModalShown: false,
      isLoading: false,
      actionButtonColor: DEFAULT_BUTTON_COLOR,
      addressInfo: { ...DEFAULT_ADDRESS_FIELDS }
    };
  },
  computed: {
    addressModalTitle() {
      return this.editAddressMode ? 'Edit address' : 'Add address';
    },
  },
  watch: {
    address() {
      this.addressInfo = { ...this.address };
    }
  },
  methods: {
    closeModal() {
      this.isAddressModalShown = false;
    },
    onActionButtonClick() {
      const { customerAddressForm } = this.$refs;

      if (!customerAddressForm.validate()) {
        return;
      }

      this.$emit('update-address');
      this.actionButtonColor = DEFAULT_BUTTON_COLOR;
    }
  }
};
</script>
